import * as React from "react"
import EyeIcon from "../images/icons/eye.inline.svg"
import { Link } from "gatsby"

const MiniHeader = () => (
  <div className="header header--mini">
     
      <div className="header__inner">
          <Link to="/" className="header__logo"><EyeIcon/> #sjekksynet</Link>
      </div>
  </div>
)


export default MiniHeader
