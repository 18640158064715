import React from "react"
import Layout from "../components/layout"
import MiniHeader from "../components/miniheader"
import Seo from "../components/seo"

export default function IndexPage({data}) {
    
    return (
        <>
        <Layout>
            <Seo title="Sjekksynet.no - Brukervilkår" />
            <MiniHeader></MiniHeader>
            <main class="container">
            <div className="content content--single">
                <div className="content__main">
                    <h1 class="u-is-green u-large-desktop">Brukervilkår</h1>
                    <h2>Om informasjonskapsler</h2>
                    <p>Dette nettstedet kan komme til å bruke informasjonskapsler, som er små datafiler som lagres på din datamaskin eller en annen datamaskin for bruk med programvaren til nettleseren din. Informasjonskapsler brukes på dette nettstedet for å gi brukeren tilgang til funksjoner som finnes på nettstedet. Informasjonskapsler brukes også for å føre statistikk over de besøkende på nettstedet. For eksempel antall besøk, antall gjenbesøk, antall sidevisninger og tid på nettstedet. Vi lagrer ingen informasjon som kan identifisere deg som person, og denne informasjonskapselen brukes bare for å kunne gjenkjenne hva som gjøres på dette nettstedet i den hensikt å forbedre tilbudet for våre besøkende.</p>
                    <p>Ved å besøke og bruke dette nettstedet samtykker du i bruken av informasjonskapsler. Du kan også samtykke i vår bruk av informasjonskapsler via innstillinger som du foretar i nettleseren din. Du kan deaktivere funksjonen for informasjonskapsler i programvaren til nettleseren din. En deaktivering av funksjonen for informasjonskapsler kan hindre deg i å bruke alle funksjonene på nettstedet.</p>
                    <h2>Brukervilkår</h2>
                    <p>
                        <strong>Opphavsrett</strong><br/>
                        Materialet på dette nettstedet tilbys av Santen Pharma AB (nedenfor omtalt som Santen) som en service til selskapets kunder og må bare brukes til informasjonsformål.
                    </p>
                    <p>
                        <strong>Begrenset bruk</strong><br/>
                        Alt innhold på denne hjemmesiden, f.eks. tekster, grafikk, logoer, knapper, ikoner, bilder, lydfiler og programvare, tilhører Santen eller dets innholdsleverandør og er beskyttet av svensk og internasjonal lovgivning. Uautorisert bruk eller distribusjon av innholdet på nettstedet kan være et brudd på lover om copyright, varemerker og/eller andre lover og kan bli gjenstand for rettslige tiltak.
                    </p>
                    <p>
                        <strong>Fraskrivelse av ansvar</strong><br/>
                        Informasjonen på nettstedet tilbys som den står der. Hvis Santen oppretter lenke til en tredjeparts hjemmeside, er denne forbindelsen bare til for brukerens bekvemmelighet, og Santen har intet ansvar for riktigheten av innholdet på en slik nettside.
                    </p>
                    <p>
                    Santen tar under ingen omstendighet ansvar for skader, uansett hva de måtte være og uten unntak, som skyldes bruk av eller manglende evne til å bruke informasjonen, heller ikke om Santen er underrettet om muligheten for slike skader.
                    Videre garanterer ikke Santen for riktigheten eller fullstendigheten av informasjon, tekst, grafikk, lenker eller andre funksjoner som kan forekomme i informasjonen. Santen kan når som helst, uten varsel, foreta endringer i innholdet eller i produktene som er beskrevet på siden. Santen forplikter seg ikke til å oppdatere informasjon eller materiale som finnes på siden.
                    </p>

                    <p><strong>Materiale fra brukere</strong><br/>
                    Santen gir deg tillatelse til å legge ut informasjon, kommentarer eller annet materiale på vårt nettsted. Dette gjøres for å lette informasjonsstrømmen om Santen og våre produkter. Alt materiale og all informasjon eller annen kommunikasjon som sendes til eller postes på dette nettstedet, vil anses som ikke-konfidensiell, ikke-eksklusiv, royaltyfri, ugjenkallelig, fullstendig “sublicensable” og ikke tilhørende noen.
                    </p>

                    <p>Santen kan vise, kopiere, distribuere, sette inn og/eller bruke all ovennevnt kommunikasjon sammen med data, bilder, lyd, tekst eller annet på siden for alle kommersielle eller ikke-kommersielle formål.</p>

                    <p>Hvis du har lagt inn personopplysninger på nettstedet eller på annen måte overført data til Santen, godkjenner du dermed at Santen bruker disse opplysningene for å vurdere informasjonen din eller for å markedsføre Santens produkter og tjenester, inkludert retten til å overføre data til et tredjeland og legge ut informasjon om dine personopplysninger på Internett. Santen tar ansvar for behandlingen av opplysningene i samsvar med den svenske personopplysningsloven.</p>

                    <p>Du får ikke poste materiale, informasjon eller annen kommunikasjon som kan anses som krenkende eller bryter mot andre personers integritet, eller som kan betraktes som kommersiell markedsføring eller som på noen måte er lovstridig eller upassende. Når Santen blir oppmerksom på slik kommunikasjon som nevnt ovenfor, vil vi fjerne den.</p>

                    <p>For bruk av tredjeparts tjenester, f.eks. Facebook, kan tredjeparts vilkår også gjelde. Før du bruker noen tredjeparts tjeneste, anbefaler vi at du leser vilkårene deres. Ikke gi oss informasjon om andre personer, hvis ikke du kan bevise at du har deres samtykke til å gjøre den tilgjengelig.</p>

                    <p><strong>Annet</strong><br/>
                    Santen kan når som helst endre disse brukervilkårene ved å oppdatere dem.</p>


                </div>
            </div>
            </main>
        </Layout>
        </>
    )
}